import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../store';
import FormBuilder, { FormData } from '../FormBuilder';
import { questions } from './Questions';

const QuestionnaireForm = () => {
    const { questions, fetchQuestions, questionnaire_app_ci_id } = useGlobalStore((state) => ({
        questions: state.questions,
        fetchQuestions: state.fetchQuestions,
        questionnaire_app_ci_id: state.questionnaire_app_ci_id,
    }), shallow)

    useEffect(() => {
        if (questionnaire_app_ci_id) {
            fetchQuestions(questionnaire_app_ci_id)
        }
    }, [questionnaire_app_ci_id])
    const formData: FormData[] = [
        // {
        //     heading: 'Form Heading 1'
        // },
        {
            questions: questions
        }
    ]

    return (
        questions.length > 0 ?
            <FormBuilder form_data={formData} config={
                { collapse_all_items: true }
            } /> : <p>No Questions found</p>

    );
};

export default QuestionnaireForm