import { Divider, Collapse, Input, Form, Button } from "antd";
import LongtextConversation from "./LongtextConversation";
import { FC } from "react";
import { FormBuilderQuestion, QuestionType } from "../../types/FormBuilder_Types";


interface FormBuilderProps {
    form_data: FormData[],
    config?: {
        collapse_all_items?: boolean
    }
};


export interface FormData {
    questions?: FormBuilderQuestion[],
    heading?: string
};

const { Panel } = Collapse;
const { TextArea } = Input



const FormBuilder: FC<FormBuilderProps> = ({ form_data, config = {} }) => {
    const [form] = Form.useForm();
    return (
        <>

            <Form form={form}>
                {
                    form_data.length == 0 ? <h2>
                        Nothing to render</h2> :
                        form_data.map((form_item) => {
                            console.log(form_item)
                            if (form_item?.heading) {
                                return <Divider>
                                    {form_item.heading}
                                </Divider>
                            }
                            if (form_item?.questions) {
                                return form_item.questions.map((que) => {
                                    if (que.type == QuestionType.LONGTEXT_CONVERSATION) {
                                        return <LongtextConversation que={que} config={config} />
                                    }

                                })
                            }
                        })
                }

            </Form >
        </>

    )
}

export default FormBuilder;