import { notification, Space, Steps } from "antd";
import ViewBuilder from "components/ViewBuilder";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import { EyeOutlined } from "@ant-design/icons";

function V2QuestionnaireTracking() {
  const [data, setData] = useState<any>([]);
  const [rowSelection, setRowSelection] = useState<any>([]);
  const navigate = useNavigate();
  const { get_questionnaire_list_api_v2 } = useGlobalStore(
    (state) => ({
      get_questionnaire_list_api_v2: state.get_questionnaire_list_api_v2,
    }),
    shallow
  );
  useEffect(() => {
    (async () => {
      let result = await get_questionnaire_list_api_v2();
      if (result.success) {
        setData(result.data);
      } else {
        notification.error({
          message: "API Error",
          description: "Failed to fetch questionnaire list. Please contact the team.",
        });
      }
    })();
  }, []);

  const muiTableBodyRowProps = ({ row }: any) => {
    return {
      onDoubleClick: (event: any) => {
        navigate("/procurement/1");
      },
      onClick: (event: any) => {
        setRowSelection({ ...rowSelection, [row.index]: rowSelection?.[row.index] ? false : true });
      },
    };
  };
  return (
    <ViewBuilder
      view_config={{
        data: data,
        hide_view_details: true,
        initial_state_react_material_table: {
          enableRowSelection: false,
          rowSelection,
          enableRowActions: true,
          renderRowActions: ({cell, row, table}:any) => {
            return (
              <Space>
                <EyeOutlined onClick={()=>navigate(`/v2/questionnaire?app_ci_id=${row.original.app_ci_id}`)}/>
              </Space>
            );
          },
        },
      }}
    />
  );
}

export default V2QuestionnaireTracking;
