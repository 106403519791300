import {fetchAuthSession} from '@aws-amplify/auth';
import AWS, {DynamoDB} from 'aws-sdk';
import {handleError} from './error_handler';
import {Key} from 'aws-sdk/clients/dynamodb';
import _ from 'lodash';
import {getUserIdentity} from "../auth";
import moment from "moment/moment";

interface Credentials {
    accessKeyId: string;
    secretAccessKey: string;
    region: string;
    sessionToken: string
}

interface DB_RESPONSE {
    data: any,
    success: boolean,
    error: Error | null,
    code: number | string
}


export class DatabaseManager {
    private dynamoDBService: DynamoDB.DocumentClient = new AWS.DynamoDB.DocumentClient();
    private credential_type: 'amplify' | 'manual' = 'amplify'
    private credentials: Credentials | null = null

    constructor(credential_type?: 'amplify' | 'manual', credential_obj?: Credentials) {
        if (credential_type === 'manual' && credential_obj) {
            this.credentials = credential_obj
            this.configureDynamoDBService();
        } else {
            // Initialize DynamoDB service without explicit credentials (use default credentials provider chain)
            this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
        }
    }

    private async configureDynamoDBService(): Promise<any> {
        // Configure DynamoDB service with provided credentials
        if (this.credential_type == 'manual') {
            AWS.config.update({
                accessKeyId: this.credentials?.accessKeyId,
                secretAccessKey: this.credentials?.secretAccessKey,
                region: this.credentials?.region ? this.credentials.region : 'us-east-1',
                sessionToken: this.credentials?.sessionToken
            });
        }
        if (this.credential_type == 'amplify') {
            const session = await fetchAuthSession()
            //@ts-ignore
            AWS.config.update({...session.credentials, region: process.env.REACT_APP_AWS_DEFAULT_REGION})
        }


        // Create DynamoDB service object
        this.dynamoDBService = new AWS.DynamoDB.DocumentClient();
    }

    public async createObject(tableName: string | undefined, item: any): Promise<DB_RESPONSE> {
        if (tableName == undefined) {
            return {
                success: false,
                error: new Error('TABLENAME NOT DEFINED'),
                data: null,
                code: 500
            }
        }
        const params: DynamoDB.DocumentClient.Put = {
            TableName: tableName,
            Item: {
                ...item,
                "created_at": moment.utc().toISOString(),
                "created_by": (await getUserIdentity()).sso_isid,
                "updated_at": moment.utc().toISOString(),
                "updated_by": (await getUserIdentity()).sso_isid
            }
        };

        try {
            await this.configureDynamoDBService()
            await this.dynamoDBService.put(params).promise();
            return {
                success: true,
                error: null,
                data: null,
                code: 200
            }
        } catch (error: any) {
            return {
                success: false,
                error: error,
                data: null,
                code: 500
            }

        }
    }

    public async deleteObject(tableName: string, key: any): Promise<void> {
        const params: DynamoDB.DocumentClient.Delete = {
            TableName: tableName,
            Key: key
        };

        try {
            await this.dynamoDBService.delete(params).promise();
        } catch (error: any) {
            handleError(error)
        }
    }

    public async getObjectById(tableName: string | undefined, id_value: string, id_key: string = "Id"): Promise<DB_RESPONSE> {
        await this.configureDynamoDBService()
        if (tableName == undefined) {
            return {
                data: null,
                success: false,
                error: new Error('tableName is undefined'),
                code: 500
            }
        }
        const params: DynamoDB.DocumentClient.GetItemInput = {
            TableName: tableName,
            Key: {[id_key]: id_value}
        };

        try {
            const data = await this.dynamoDBService.get(params).promise();
            if (!data.Item) {
                return {
                    data: null,
                    error: new Error(`RECORD_NOT_FOUND`),
                    success: false,
                    code: 401
                }
            }
            return {
                data: data.Item,
                success: true,
                error: null,
                code: 200
            }
        } catch (error: any) {
            return {
                success: false,
                error,
                data: null,
                code: 500
            }
        }
    }

    public async getAllObjects(tableName: string, fields?: string[], limit?: number, startKey?: any): Promise<{
        items: any[],
        nextStartKey: Key | undefined
    }> {
        await this.configureDynamoDBService();
        const params: DynamoDB.DocumentClient.ScanInput = {
            TableName: tableName,
            ProjectionExpression: fields ? fields.join(', ') : undefined,
            Limit: limit,
            ExclusiveStartKey: startKey,
        };

        try {
            const data = await this.dynamoDBService.scan(params).promise();
            const items: any[] = data.Items || [];
            // If there are more records, return a start key for pagination
            const nextStartKey = data.LastEvaluatedKey;
            return {items, nextStartKey};
        } catch (error: any) {
            handleError(error);
            return {items: [], nextStartKey: undefined};
        }
    }

    public async updateRecord(TableName: string | undefined, IdKey: string, IdValue: string | number,
                              PathToUpdate: string, ValueToUpdate: any):
        Promise<{
        success: boolean,
        data: DynamoDB.DocumentClient.AttributeMap | undefined | null,
        error: Error | null
    }> {
        try {
            if (_.isEmpty(TableName)) {
                throw new Error("Table Name Not Provided")
            }
            await this.configureDynamoDBService();
            let input = {
                TableName,
                Key: {
                    [IdKey]: IdValue,
                },
                ...getUpdateParamsFromPath(PathToUpdate, ValueToUpdate),
                ReturnValues: 'NONE',
            };
            const {Attributes: updatedRecord} = await this.dynamoDBService.update(input).promise();
            return {
                success: true,
                data: updatedRecord,
                error: null
            }
        } catch (error: any) {
            handleError(error)
            return {
                success: false,
                error: error,
                data: null
            }
        }
    }

    public getDynamoDBService = () => {
        return this.dynamoDBService
    }
}

export function getUpdateParamsFromPath(path: string, value: any) {
    let pathSegments = path.split('.')
    let _pathSegments = pathSegments.map((seg) => '#' + seg.replaceAll(/[ \/]/g, '_'))
    let result: any = {
        UpdateExpression: '',
        ExpressionAttributeNames: {},
        ExpressionAttributeValues: {}
    }

    pathSegments.forEach((seg, idx) => {
        result.ExpressionAttributeNames[_pathSegments[idx]] = seg
    })
    result.UpdateExpression = 'SET ' + _pathSegments.join('.') + ' = :value'
    result.ExpressionAttributeValues = {':value': value}
    return result

}
