const questionnaire_version_map: {
    [key: number]: {
        ADMIN_COMMENTS_PATH: string,
        RESPONDER_COMMENTS_PATH: string,
        STATUSES_PATH: string
    }
} = {
    1: {
        ADMIN_COMMENTS_PATH: 'admin_comments',
        RESPONDER_COMMENTS_PATH: 'responder_comments',
        STATUSES_PATH: 'statuses'
    }
}

export default questionnaire_version_map