import * as auth from 'aws-amplify/auth';
import { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react-core";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import useGlobalStore from "store";
import { shallow } from "zustand/shallow";
import { fetchAuthSession } from '@aws-amplify/core'
import { saveUserInfo } from 'services/migserv_auth';
import WebSocketConnect from 'components/common/WebSocketConnect';


const Authenticator = ({ children }) => {
    const { authStatus } = useAuthenticator()
    const [master_data_fetched, set_master_data_fetched] = useState(false)
    const [current_session, set_current_session] = useState(null)
    const { fetch_and_configure_master_data } = useGlobalStore(
        (state) => ({
            fetch_and_configure_master_data: state.fetch_and_configure_master_data,
        }),
        shallow
    );

    useEffect(() => {
        (async () => {
            try {
                if (authStatus !== 'authenticated') {
                    if (window.location.pathname !== "/") {
                        window.localStorage.setItem('redirect_to_path', window.location.pathname + window.location.search)
                    }
                    await auth.signInWithRedirect()
                } else {
                    notification.info({
                        message: "Fetching Data",
                        description: "We are fetching important data for the application...",
                        key: "opening_notif",
                        icon: <LoadingOutlined />,
                        duration: 0,
                    });
                    fetch_and_configure_master_data().then((result) => {
                        if (result) {
                            notification.success({
                                message: "Fetched Data Successfully",
                                key: "opening_notif",
                                duration: 2,
                            });
                            set_master_data_fetched(true)
                        } else {
                            notification.destroy("fetching_data");
                        }
                    });
                    let session = await fetchAuthSession()
                    set_current_session(session)
                    await saveUserInfo(session)
                }

            } catch (e) {
                if (e instanceof auth.AuthError) {
                    if (e.name == "UserAlreadyAuthenticatedException") {
                        return
                    }
                }
                notification.error({ message: 'Error signing in, contact app admin ...' })
                console.log(e)
            }
        })()


    }, [authStatus])
    return <>{authStatus == 'authenticated' && master_data_fetched && current_session != null ?
        process.env.REACT_APP_DISABLE_WEBSOCKET == 'true' ? children : [<WebSocketConnect session={current_session} />, children]
        : []}</>
}

export default Authenticator