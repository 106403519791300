import appAxios from "../components/app_config/axios";
import * as storage from "../services/migserv_storage";
import { AxiosError, isAxiosError } from "axios";
import { notification } from "antd";
import moment from "moment";
import { AllStoreSlices } from "store";
import _ from "lodash";
import { DatabaseManager } from "services/aws/db";
import {getUserIdentity} from "../services/auth";

type ApiResponse = {
  success: boolean;
  error: any;
  data: any;
  code: number | string;
};
const db_manager = new DatabaseManager()
export interface ApiServiceStoreInterface {
  delete_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  mark_as_read_api: (
    event_id: null | number,
  ) => Promise<ApiResponse>;
  create_approvers_testers_api: (
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api: (
    method: "put" | "delete",
    manage_type: string,
    workflow: string,
    schedule_id: string,
    approver_tester_str: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  _approvers_testers_api$?: any;
  approval_status_api: (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => Promise<ApiResponse>;
  get_config_api: (migtype: string[], config_properties: string[]) => Promise<ApiResponse>;
  get_questionnaire_list_api: () => Promise<ApiResponse>;
  get_questionnaire_list_api_v2: () => Promise<ApiResponse>;
  post_approvals_update_api: (
    week_name: string,
    file_name?: string
  ) => Promise<ApiResponse>;
  linked_entities: (
    method: "get" | "post" | "put",
    schedule_id: string,
    show_notification: boolean
  ) => Promise<ApiResponse>;
  v2_questionnaire_api: (
    method: "get" | 'post' | 'put',
    app_ci_id: string,
    body?: any
  ) => Promise<ApiResponse>;
}
export const createApiServiceStoreSlice = (set: (state: any) => any, get: () => AllStoreSlices): ApiServiceStoreInterface => ({
  _approvers_testers_api$: null,
  mark_as_read_api: async (event_id) => {
    try {
      let result = await appAxios.request({
        method: 'PATCH',
        url: '/events',
        params: { mark_all: event_id == null ? 'true' : 'false', user: storage.get("user.isid", "") },
        data: event_id ? { event_ids: [event_id] } : {}
      })
      if (result.status == 201) {
        return {
          success: true,
          code: "201",
          data: true,
          error: null,
        };
      }
      return handleError(new Error(result.statusText))

    } catch (e) {
      return handleError(e)
    }
  },
  delete_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("delete", ...args);
  },
  create_approvers_testers_api: async (...args) => {
    return get()._approvers_testers_api("put", ...args);
  },
  _approvers_testers_api: async (
    method: "put" | "delete",
    manage_type,
    workflow,
    schedule_id,
    approver_tester_str,
    show_notification = false
  ) => {
    try {
      let params: { [key: string]: string } = {
        //@ts-ignore
        username: storage.get("user.isid", ""),
        [manage_type.toLowerCase()]: approver_tester_str,
        workflow,
        schedule_id,
      };
      let urlParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        urlParams.set(key, params[key]);
      });

      let result = await appAxios.request({
        url: `/${manage_type === "APPROVER" ? "approvers" : "testers"}?${urlParams.toString()}`,
        method,
        data: {},
      });
      if (show_notification) {
        notification.success({ message: `${manage_type.toLowerCase()} ${method === "put" ? "added" : "removed"}` });
      }
      set({
        _approvers_testers_api$: {},
      });
      return {
        success: true,
        code: result?.status ? result.status.toString() : "200",
        data: result.data,
        error: null,
      };
    } catch (e: any | AxiosError) {
      if (show_notification) {
        notification.error({
          message: `Failed to ${method === "put" ? "add" : "remove"} ${manage_type.toLowerCase()}`,
          description: e?.message ? e.message : "",
        });
      }
      return handleError(e);
    }
  },
  approval_status_api: async (
    status: "RESCHEDULE REQUESTED" | "DROP REQUESTED",
    app_ci_id: string,
    servername: string,
    workflow: string,
    comment: string
  ) => {
    try {
      let result = await appAxios.request({
        url: `/approval_status`,
        params: {
          app_ci_id,
          servername,
          workflow,
          username: storage.get("user.isid", ""),
        },
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          status,
          comment,
        }),
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_config_api: async (migtype = [], config_properties = [""]) => {
    try {
      let result = await appAxios.request({
        url: `/config`,
        params: {
          in_migtype: migtype.length == 0 ? "" : migtype.join(","),
          in_config: config_properties.length == 0 ? "" : config_properties.join(","),
        },
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_questionnaire_list_api: async () => {
    try {
      let result = await appAxios.request({
        url: `/questions`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return {
        success: true,
        error: null,
        data: result.data,
        code: result.status.toString(),
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  get_questionnaire_list_api_v2: async () => {
    try {
      let questionnaireTableName = process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME ? process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME : ''
      let data = await db_manager.getAllObjects(
          questionnaireTableName,
          ['app_ci_id', 'version_number'], 50)

      return {
        success: true,
        error: null,
        data: data.items,
        code: '200',
      };
    } catch (e: any | AxiosError) {
      return handleError(e);
    }
  },
  post_approvals_update_api: async (week_name, file_name = moment().unix().toString()) => {
    try {
      let result = await appAxios.request({
        baseURL: process.env.REACT_APP_MIGSERV_API_URL,
        url: `/content`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          week_data: week_name,
          filename: file_name
        }
      });
      let timeout = setTimeout(() => {
        notification.error({ message: `Your approvals update for week: ${week_name} might have been failed`, description: 'Since you selected week for the update, it has been 5 minutes. Please try again or contact admin.' })
      }, 12 * 60000)
      get().watch_event('APPROVAL_DATA_UPDATE', file_name, function () { clearTimeout(timeout) })
      // let result: any = {}
      return {
        success: true,
        error: null,
        data: result?.data,
        code: result?.status?.toString(),
      };
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }
  },
  linked_entities: async (method, schedule_id, show_notification) => {
    try {
      let result = await appAxios.request({
        url: '/approvers',
        method: method.toUpperCase(),
        params: {
          sched_id: schedule_id
        }
      })
      return {
        success: true,
        code: '200',
        error: null,
        data: result.data
      }
    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  },
  v2_questionnaire_api: async (method, app_ci_id, body) => {
    try {
      if (method == 'get') {
        let obj = await db_manager.getObjectById(process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME, app_ci_id, 'app_ci_id')
        if (obj.success) {
          return {
            success: true,
            data: obj.data,
            error: null,
            code: 200
          }
        } else {
          return { ...obj }
        }
      }
      if (method == 'post') {
        if (_.isObject(body)) {
          let obj = await db_manager.createObject(process.env.REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME, body)
          return obj
        }
      }
      if (method == 'put') {
        let db_service = db_manager.getDynamoDBService()
        let UpdateExpression = 'SET '
        let key = ''
        let ExpressionAttributeValues: any = {}
        let date_now_utc = moment.utc().toISOString()

        let updated_by = (await getUserIdentity()).sso_isid
        Object.keys(body).forEach((question_id) => {
          key = question_id
          if (body[question_id]['admin_comment']) {
            UpdateExpression += 'admin_comments.#key = :admin_comment, '
            ExpressionAttributeValues[':admin_comment'] = body[question_id]['admin_comment']
          }
          if (body[question_id]['responder_comment']) {
            UpdateExpression += 'responder_comments.#key = :responder_comment, '
            ExpressionAttributeValues[':responder_comment'] = body[question_id]['responder_comment']
          }
          if (body[question_id]['status']) {
            UpdateExpression += 'statuses.#key = :status'
            ExpressionAttributeValues[':status'] = body[question_id]['status']
          }

          UpdateExpression = UpdateExpression.trim()
        })
        UpdateExpression += 'updated_at = :updated_at, '
        ExpressionAttributeValues[':updated_at'] = date_now_utc

        UpdateExpression += 'updated_by = :updated_by'
        ExpressionAttributeValues[':updated_by'] = updated_by

        const params = {
          TableName: _.get(process.env, 'REACT_APP_DYNAMO_DB_QUESTIONNAIRE_TABLE_NAME', ''),
          Key: {
            'app_ci_id': app_ci_id // Assuming 'app_ci_id' is the partition key
          },
          UpdateExpression: UpdateExpression.endsWith(',') ? UpdateExpression.substring(0, UpdateExpression.length - 1) : UpdateExpression,
          ExpressionAttributeNames: {
            '#key': key // Assuming you're updating under key '1'
          },
          ExpressionAttributeValues,
          ReturnValues: 'ALL_NEW' // Specify the desired return values
        };
        let result = await db_service.update(params).promise()
        return {
          success: true,
          code: 200,
          data: result.Attributes,
          error: null
        }
      }
      return {
        success: false,
        code: 500,
        data: null,
        error: null
      }

    } catch (e: any | AxiosError) {
      console.log(e)
      return handleError(e);
    }

  }
});

const handleError = (e: any) => {
  if (isAxiosError(e)) {
    return {
      success: false,
      data: null,
      error: e.message,
      code: e?.code ? e.code : "500",
    };
  }
  return {
    success: false,
    data: null,
    error: e,
    code: "500",
  };
};
