import { Row, Col, Space, Divider, Steps, Breadcrumb } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import QuestionnaireForm from "../components/Questionnaire/QuestionnaireForm";
import { useSearchParams } from "react-router-dom";
import useGlobalStore from "../store";
import { shallow } from "zustand/shallow";
import QuestionnaireDescription from "../components/Questionnaire/QuestionnaireDescription";
import QuestionnaireTracking from "components/Questionnaire/QuestionnaireTracking";

interface QuestionnairePageProps {}

const QuestionnairePage: FC<QuestionnairePageProps> = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showTrackingView, setShowTrackingView] = useState(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    {
      title: "Home",
    },
    {
      title: <p onClick={() => navigate("/questionnaire")}>Questionnaire Tracking</p>,
    },
  ]);
  const { setQuestionnaireAppCid, questionnaire_app_ci_id } = useGlobalStore(
    (state) => ({
      setQuestionnaireAppCid: state.setQuestionnaireAppCid,
      questionnaire_app_ci_id: state.questionnaire_app_ci_id,
    }),
    shallow
  );

  useEffect(() => {
    let app_ci_id = searchParams.get("app_ci_id");
    if (app_ci_id !== null && questionnaire_app_ci_id !== app_ci_id) {
      setQuestionnaireAppCid(app_ci_id);
      setShowTrackingView(false);
      setBreadcrumbItems([...breadcrumbItems, { title: "Questionnaire: " + app_ci_id }]);
    } else {
      setShowTrackingView(true);
      setBreadcrumbItems(breadcrumbItems.slice(0, 2));
    }
  }, [searchParams]);

  return (
    <>
      <Row justify={"center"} align="middle" style={{ minHeight: "8vh" }}>
        <Col span={20}>
          <Space direction="horizontal">
            <Breadcrumb items={breadcrumbItems} />
          </Space>
        </Col>
        <Col span={10}></Col>
      </Row>
      {showTrackingView ? (
        <></>
      ) : (
        <Row justify={"center"} gutter={{ sm: 8, md: 16, lg: 32 }}>
          <Col span={18}>
            <QuestionnaireDescription app_ci_id={searchParams.get("app_ci_id")} />
          </Col>
        </Row>
      )}

      <Row justify={"center"} gutter={{ sm: 8, md: 16, lg: 32 }}>
        {/* <Divider /> */}
        <Col span={20} style={{ backgroundColor: "rgba(128, 128, 128, 0.04)" }}>
          {showTrackingView ? <QuestionnaireTracking /> : <QuestionnaireForm />}
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default QuestionnairePage;
