import { Menu } from "antd";
import type { MenuProps } from "antd";
import { MenuOutlined, QqSquareFilled } from '@ant-design/icons'
import { useNavigate, useLocation } from "react-router-dom";
import { FC, useEffect } from "react";

const AppMenu: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(()=>{
    console.log(location.pathname)
  },[location])
 
  
  let MenuItems: any = [{
    label: 'Menu',
    icon: <MenuOutlined />,
    children: [
      {
        label: 'App Manager',
        key: '/app-manager',
      },
      {
        label: 'Scheduler',
        key: '/sched-approvers'
      },
    ]
  }]
  return <Menu
    onClick={(e) => {
      navigate(e.key)
     }}
    selectedKeys={[location.pathname]}
    mode={'horizontal'}
    theme={'light'}
    items={MenuItems}>
  </Menu>
}

export default AppMenu