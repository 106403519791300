import axios, { AxiosError } from "axios"
import appAxios from "../components/app_config/axios"
import * as storage from "../services/migserv_storage"

import { FormBuilderQuestion, QuestionResponseObject, QuestionType } from "../types/FormBuilder_Types"

const apiUrl = process.env.REACT_APP_MIGSERV_API_URL ? process.env.REACT_APP_MIGSERV_API_URL : ''

export interface QuestionnaireStoreInteface {
    fetchQuestions: (app_ci_id: string, transform?: boolean) => any,
    questions: FormBuilderQuestion[],
    questionnaire_app_ci_id: string,
    transformResponseToLocalQuestions: (response_questions: {
        question_id: number,
        question_name: string,
        question_descr: string,
        question_response: string | null
    }[], app_ci_id: string) => any,
    setQuestionResponse?: (question_id: number, question: FormBuilderQuestion) => any,
    setQuestionnaireAppCid: (app_ci_id: string) => any
}
export const createQuestionnaireSlice = (set: (state: any) => any, get: () => any): QuestionnaireStoreInteface => ({
    fetchQuestions: async (app_ci_id: string, transform = true) => {
        try {
            const response = await appAxios.get(apiUrl + '/questions', {
                params: {
                    app_ci_id
                }
            })
            if (transform) {
                get().transformResponseToLocalQuestions(response.data ? response.data : [], app_ci_id)
            }
            return {
                success: true
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                get().setAlert(e.message)
            }
        }

    },
    questionnaire_app_ci_id: '',
    setQuestionnaireAppCid: (app_ci_id = '') => set({ questionnaire_app_ci_id: app_ci_id }),
    transformResponseToLocalQuestions: (response_questions, app_ci_id) => {
        let localQue: FormBuilderQuestion | any = {}
        let localQueRes: QuestionResponseObject[] = []
        let responses: any[] = []
        let isid = storage.get('user.isid')
        let localQuestions = response_questions.map((que, index) => {
            responses = []
            localQueRes = []

            localQue = {
                question_id: que.question_id,
                question_name: que.question_name,
                question_descr: que.question_descr,
                type: QuestionType.LONGTEXT_CONVERSATION,
                category: 'Scheduling',
                individual_submission_url: `${apiUrl}/question_responses?app_ci_id=${app_ci_id}&username=${isid}&question_id=${que.question_id}`

            }
            if (que?.question_response) {
                responses = que.question_response.split(';')
                let res_split = []
                localQueRes = responses.map((res) => {
                    res_split = res.split(' ')
                    return {
                        timestamp: typeof (res_split[0]) === 'string' ? res_split[0] + ' ' + res_split[1] : null,
                        user_id: res_split[2],
                        value: res_split.splice(4, res_split.length - 1).join(' ')
                    }
                })


            }
            localQue['question_response'] = localQueRes
            return localQue
        })
        set({ questions: localQuestions })
    },
    questions: []
})