import { Col, Row, Input, Button, Space, Checkbox, Typography } from "antd"
import _ from "lodash"
import moment from "moment"
import { useState } from "react"
import { STATUS_TYPES } from "./types"

const V2SingleQuestion = ({
    admin_comment = { timestamp: '', value: '' },
    responder_comment = { timestamp: '', value: '' },
    questionnaire_admin = false,
    questionnaire_key = null,
    status = STATUS_TYPES.UNANSWERED,
    on_submit
}) => {
    const { TextArea } = Input
    const { Text } = Typography
    let default_props = {
        admin_comment: admin_comment.value,
        responder_comment: responder_comment.value,
        status
    }
    const [form_data, set_form_data] = useState(default_props)

    const onSubmit = () => {
        let submit_data = {}
        let timestamp = moment().unix()
        //if the object does not exist, create it first. 
        if (form_data.admin_comment !== admin_comment.value) {
            //Submit Admin Comment
            submit_data['admin_comment'] = { timestamp, value: form_data.admin_comment }
        }
        if (form_data.responder_comment !== responder_comment.value) {
            //submit Responder comment
            submit_data['responder_comment'] = { timestamp, value: form_data['responder_comment'] }
            if (form_data.status == STATUS_TYPES.UNANSWERED) {
                submit_data['status'] = STATUS_TYPES.SUBMITTED
            }
        }
        if (form_data.status !== status) {
            //submit review status
            submit_data['status'] = form_data['status']
        }
        if (Object.keys(submit_data).length != 0) {
            if (_.isFunction(on_submit)) {
                on_submit({ [questionnaire_key]: submit_data })
            }
        }
    }

    return (<Row gutter={[10, 10]}>
        <Col span={24}>
            <p>Admin's Comment</p>
            <TextArea rows={1} value={form_data.admin_comment}
                onChange={(e) => set_form_data({ ...form_data, admin_comment: e.target.value })}
                style={{ color: 'red' }}
                disabled={!questionnaire_admin || form_data.status == STATUS_TYPES.VALID}
                placeholder={!questionnaire_admin && _.isEmpty(admin_comment.value) ? 'No comments are provided by admin' : ''} />
            <Text disabled>{_.isEmpty(admin_comment.value) ? '' : moment.unix(admin_comment.timestamp).local().toString()}</Text>
        </Col>
        <Col span={24}>
            {questionnaire_admin ? <p>Response: </p> : <p>Type your response below</p>}
            <Row>
                <Col span={24}>
                    <TextArea rows={3}
                        value={form_data.responder_comment}
                        onChange={(e) => set_form_data({ ...form_data, responder_comment: e.target.value })}
                        disabled={questionnaire_admin || form_data.status == STATUS_TYPES.VALID}
                        placeholder={questionnaire_admin && _.isEmpty(form_data.responder_comment) ? 'No comments are provided by the responder' : ' '}
                    />
                </Col>
                <Col span={24}>
                    <Text disabled>{_.isEmpty(responder_comment.value) ? '' : moment.unix(responder_comment.timestamp).local().toString()}</Text>
                </Col>
            </Row>
        </Col>
        {questionnaire_admin ? <Col span={24}>
            <Checkbox
                onChange={(e) => set_form_data({ ...form_data, status: e.target.checked ? STATUS_TYPES.VALID : STATUS_TYPES.SUBMITTED })}
                checked={form_data.status == STATUS_TYPES.VALID}> Mark as reviewed</Checkbox>
        </Col> : <></>}
        <Col span={24}>
            <Button onClick={onSubmit}>Submit</Button>
        </Col>
    </Row>)
}

export default V2SingleQuestion