import appAxios from "components/app_config/axios";
import _ from "lodash";


let all_dates = [
  "approvals_instruction_date",
  "approvals_instruction_time",
  "application_shutdown_notice",
  "downtime_begins_date",
  "downtime_begins_time",
  "downtime_complete_date",
  "downtime_complete_time",
  "hypercare_begins_date",
  "hypercare_begins_time",
  "migration_complete",
  "migweek",
  "migdate"
]
export const comms_email_config = {
  "BlueSky Rehost": {
    "email_approval": {
      "date_config": all_dates,
      "template_html_path": "/email_templates/approval/approval_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,robert.digiovanni@merck.com,bluesky_network_te@merck.com"
    },
    "email_app_shutdown": {
      "email_subject_suffix": "Application Shutdown",
      "action_required": false,
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/app_shutdown/bluesky.html",
      "cc_list": "bluesky_div_it@merck.com,bluesky_migration_@merck.com,bluesky_network_te@merck.com,bluesky_network_te@merck.com,edward.shows1@merck.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/downtimestart_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,bluesky_network_te@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/downtimecomplete_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,bluesky_network_te@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,bluesky_network_te@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,bluesky_network_te@merck.com"
    }
  },
  "MCS": {
    "email_approval": {
      "date_config": all_dates,
      "template_html_path": "/email_templates/approval/approval_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,edward.shows@hpe.com,edward.shows1@merck.com,robert.digiovanni@merck.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/downtimestart_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/downtimecomplete_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_bluesky.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "GreenLake": {
    "email_approval": {
      "date_config": [
        ...all_dates,
        "approval_due_date"
      ],
      "template_html_path": "/email_templates/approval/approval_greenlake_apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_app_shutdown": {
      "email_subject_suffix": "Application Shutdown",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_weekday",
          "date_format": "dddd"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_weekday",
          "date_format": "dddd"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/app_shutdown/greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/greenlake_apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,it_security_tech_operations@merck.com,michael.hall1@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "GreenLake SRM": {
    "email_approval": {
      "date_config": [
        ...all_dates,
        "approval_due_date"
      ],
      "template_html_path": "/email_templates/approval/approval_greenlake_srm.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_app_shutdown": {
      "email_subject_suffix": "Application Shutdown",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_weekday",
          "date_format": "dddd"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_weekday",
          "date_format": "dddd"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/app_shutdown/greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/greenlake_apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,it_security_tech_operations@merck.com,michael.hall1@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_greenlake.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "GreenLake Live": {
    "email_approval": {
      "date_config": all_dates,
      "template_html_path": "/email_templates/approval/approval_greenlake_live.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_greenlakelive.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_greenlakelive.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "APEX": {
    "email_approval": {
      "date_config": all_dates,
      "template_html_path": "/email_templates/approval/approval_apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_app_shutdown": {
      "email_subject_suffix": "Application Shutdown",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_weekday",
          "date_format": "dddd"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_weekday",
          "date_format": "dddd"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/app_shutdown/apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/apex.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "Robo": {
    "email_approval": {
      "date_config": [
        ...all_dates,
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        }
      ],
      "template_html_path": "/email_templates/approval/approval_robo.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,De Leon, Leonard <leonard.deleon@merck.com>; Keiser, Ben <robert.digiovanni@merck.com>; Guitar, Sean <sean.guitar@merck.com>; ,sean.guitar@hpe.com,; HPEWinLinTeamLeads <hpewinlinmigration@merck.com>; ,edward.shows@hpe.com,edward.shows1@merck.com; Winslow, Kent <kent.winslow@merck.com>; Burns, John <john_burns2@merck.com>; Jablonski, Maria <maria.jablonski@merck.com>; Patel, Manhar <manhar_patel@merck.com>; Thm, Santosh Kumar <santosh.kumar.thm@merck.com>; Konopka, Steve <steve.konopka@merck.com>; Xie, Guochun <guochun_xie@merck.com>; Murdoch, Jeff <jeff_murdoch@merck.com>"
    },
    "email_app_shutdown": {
      "email_subject_suffix": "Application Shutdown",
      "action_required": false,
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/app_shutdown/robo.html",
      "cc_list": "bluesky_div_it@merck.com,bluesky_migration_@merck.com,bluesky_network_te@merck.com,bluesky_network_te@merck.com,edward.shows1@merck.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        ...all_dates,
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_date",
          "date_format": "MMMM DD, YYYY"
        }
      ],
      "template_html_path": "/email_templates/downtime_start/downtimestart_robo.html",
      "cc_list": "santosh.kumar.thm@merck.com,edward.shows1@merck.com,edward.shows@hpe.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        ...all_dates,
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_date",
          "date_format": "MMMM DD, YYYY"
        }
      ],
      "template_html_path": "/email_templates/downtime_complete/downtimecomplete_robo.html",
      "cc_list": "santosh.kumar.thm@merck.com,edward.shows1@merck.com,edward.shows@hpe.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        ...all_dates,
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "hypercare_begins_date",
          "output_prop_name": "_hypercare_begins_date",
          "date_format": "MMMM DD, YYYY"
        }
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_robo.html",
      "cc_list": "santosh.kumar.thm@merck.com,edward.shows1@merck.com,edward.shows@hpe.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        ...all_dates,
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        {
          "date_config_prop_name": "downtime_complete_date",
          "output_prop_name": "_downtime_complete_date",
          "date_format": "MMMM DD, YYYY"
        }
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_robo.html",
      "cc_list": "santosh.kumar.thm@merck.com,edward.shows1@merck.com,edward.shows@hpe.com,chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com"
    }
  },
  "DC": {
    "email_approval": {
      "date_config": all_dates,
      "template_html_path": "/email_templates/approval/approval_dc.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,De Leon, Leonard <leonard.deleon@merck.com>, Keiser, Ben <robert.digiovanni@merck.com>, Guitar, Sean <sean.guitar@merck.com>, sean.guitar@hpe.com, HPEWinLinTeamLeads <hpewinlinmigration@merck.com>, edward.shows@hpe.com,  Winslow, Kent <kent.winslow@merck.com>,     Burns, John <john_burns2@merck.com>, Jablonski, Maria <maria.jablonski@merck.com>, Patel, Manhar <manhar_patel@merck.com>, Thm, Santosh Kumar <santosh.kumar.thm@merck.com>, Konopka, Steve <steve.konopka@merck.com>, Xie, Guochun <guochun_xie@merck.com>, Murdoch, Jeff <jeff_murdoch@merck.com>,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_downtime_start": {
      "email_subject_suffix": "Downtime Start",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_start/downtimestart_dc.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,De Leon, Leonard <leonard.deleon@merck.com>, Keiser, Ben <robert.digiovanni@merck.com>, Guitar, Sean <sean.guitar@merck.com>, sean.guitar@hpe.com, HPEWinLinTeamLeads <hpewinlinmigration@merck.com>, edward.shows@hpe.com,  Winslow, Kent <kent.winslow@merck.com>,     Burns, John <john_burns2@merck.com>, Jablonski, Maria <maria.jablonski@merck.com>, Patel, Manhar <manhar_patel@merck.com>, Thm, Santosh Kumar <santosh.kumar.thm@merck.com>, Konopka, Steve <steve.konopka@merck.com>, Xie, Guochun <guochun_xie@merck.com>, Murdoch, Jeff <jeff_murdoch@merck.com,edward.shows1@merck.com"
    },
    "email_downtime_complete": {
      "email_subject_suffix": "Downtime Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/downtime_complete/downtimecomplete_dc.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,De Leon, Leonard <leonard.deleon@merck.com>, Keiser, Ben <robert.digiovanni@merck.com>, Guitar, Sean <sean.guitar@merck.com>, sean.guitar@hpe.com, HPEWinLinTeamLeads <hpewinlinmigration@merck.com>, edward.shows@hpe.com,  Winslow, Kent <kent.winslow@merck.com>,     Burns, John <john_burns2@merck.com>, Jablonski, Maria <maria.jablonski@merck.com>, Patel, Manhar <manhar_patel@merck.com>, Thm, Santosh Kumar <santosh.kumar.thm@merck.com>, Konopka, Steve <steve.konopka@merck.com>, Xie, Guochun <guochun_xie@merck.com>, Murdoch, Jeff <jeff_murdoch@merck.com,edward.shows1@merck.com"
    },
    "email_hypercare_start": {
      "email_subject_suffix": "Hypercare Begins",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/hypercare_start/hypercare_dc.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    },
    "email_migration_complete": {
      "email_subject_suffix": "Migration Complete",
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_complete/migcomplete_dc.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,robert.digiovanni@merck.com,hpewinlinmigration@merck.com,edward.shows@hpe.com,edward.shows1@merck.com"
    }
  },
  "Default": {
    "email_approval_confirmation": {
      "template_html_path": "/email_templates/approval_confirmation.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,edward.shows1@merck.com,edward.shows@hpe.com"
    },
    "email_request_migration": {
      "template_html_path": "/email_templates/request_migration.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,paul.wilmot@merck.com,edward.shows1@merck.com,edward.shows@hpe.com"
    },
    "email_drop_request": {
      "template_html_path": "/email_templates/drop_request.html",
      "to_list": "mig_scheduling@merck.com"
    },
    "email_reschedule_request": {
      "template_html_path": "/email_templates/request_reschedule.html",
      "to_list": "mig_scheduling@merck.com"
    },
    "email_migration_schedule": {
      "date_config": [
        {
          "date_config_prop_name": "downtime_begins_date",
          "output_prop_name": "_downtime_begins_date",
          "date_format": "MMMM DD, YYYY"
        },
        ...all_dates
      ],
      "template_html_path": "/email_templates/migration_schedule/migration_schedule_common.html",
      "cc_list": "chris.ryan@merck.com,aurelie.guitar@merck.com,Shows, Edward <edward.shows1@merck.com>; Gan, Tom W <thomas_gan@merck.com>; HPEWinLinMigrationTeam <hpewinlinmigration@merck.com>; Hillegass, Scott K <scott_hillegass@merck.com>; Hall, Michael <michael.hall1@merck.com>; merck-migration-support@hpe.com, paul.wilmot@merck.com,edward.shows@hpe.com",
      "email_subject_suffix": "Migration Schedule"
    }
  }
};

export const get_comms_email_config = (migtype, comm_email_type, default_value = null) => {
  if (comms_email_config?.[migtype]?.[comm_email_type]) {
    return { ...comms_email_config[migtype][comm_email_type] };
  } else {
    return default_value
  }
};
export const get_comms_email_config_with_cache = async (migtype, comm_email_type, default_value = null) => {
  let baseURL = process.env.REACT_APP_MIGSERV_API_URL + '/email_templates'
  if (comms_email_config?.[migtype]?.[comm_email_type]) {
    if (comms_email_config[migtype][comm_email_type]['template_html_path'] && typeof (comms_email_config[migtype][comm_email_type]?.['template_html']) !== 'string') {
      try {
        let result = await appAxios.request({
          baseURL,
          url: comms_email_config[migtype][comm_email_type]['template_html_path'],
          method: "GET",
        });
        if (result?.data) {
          set_comms_email_config(migtype, comm_email_type, { ...comms_email_config[migtype][comm_email_type], template_html: result.data })
        }
      } catch (e) {
        return `No Template Found for Migtype: ${migtype} `;
      }
    }
    return { ...comms_email_config[migtype][comm_email_type] };
  } else {
    return default_value
  }
};

export const clear_comms_email_config_cache = (cache_key) => {
  Object.keys(comms_email_config).forEach((comm) => {
    comms_email_config[comm] = _.omit(comms_email_config[comm], cache_key)
  })
  return true

}

export const set_comms_email_config = (migtype, comm_email_type, value) => {
  comms_email_config[migtype][comm_email_type] = value
  return value
}

let struct = {
  migration_type: {
    comm_email_type: {},
  },
};
