import { useEffect, useState } from "react"
import { getQueryParams } from "services/helper"
import useGlobalStore from "store"
import { shallow } from 'zustand/shallow';
import QuestionnaireTemplateJson from './QuestionnaireTemplate.json'
import V2SingleQuestion from "./v2_SingleQuestion";
import { GlobalRender } from 'react-antd-json-renderer'
import _ from "lodash";
import { Button, Col, Divider, Row, notification } from "antd";
import { sleep } from "services/migserv_utils";
import questionnaire_version_map from "./questionnaire_version_map";
import { STATUS_TYPES, STATUS_TYPES_META } from "./types";
import { retrieveUserInfo } from "services/migserv_auth";
import { fetchAuthSession } from "@aws-amplify/core";

const V2Questionnaire = () => {
  const { v2_questionnaire_api } = useGlobalStore(
    (state) => ({
      v2_questionnaire_api: state.v2_questionnaire_api
    }),
    shallow
  );

  let [questionnaire_data, set_questionnaire_data] = useState({ original: {}, ui: {} })

  useEffect(() => {
    (async () => {
      let data = await fetch_or_create_questionnaire()
      set_questionnaire_data({ original: data, ui: await process_questionnaire_for_ui(data) })
    })()
  }, [])

  const fetch_or_create_questionnaire = async () => {
    let app_ci_id = getQueryParams()['app_ci_id']
    notification.info({ message: `Fetching Questionnaire for app-ci-id: ${app_ci_id}`, key: 'fetch' })
    let response = await v2_questionnaire_api('get', app_ci_id)
    if (response.success) {
      notification.success({ message: `Fetched Questionnaire successfully`, key: 'fetch' })
      return response.data
    } else {
      if (response.code == 401) {
        notification.info({ message: `Questionnaire does not exist. Creating one, please wait.. `, key: 'fetch' })
        let response = await v2_questionnaire_api('post', app_ci_id, { ...QuestionnaireTemplateJson, app_ci_id })

        if (response.success) {
          notification.success({
            message: 'Questionnaire initialized successfully, refreshing window ...', key: 'fetch'
          })
          await sleep(1500)
          window.location.reload()
          return
        }
      }
      notification.error({
        message: 'Some Error Occured. Please refresh the page and try again ...',
        key: 'fetch'
      })
    }

    return null
  }

  const on_question_submit = async (question_key, changed_values) => {
    notification.info({
      message: 'Updating questionnaire',
      key: 'update'
    })
    let result = await v2_questionnaire_api('put', getQueryParams()['app_ci_id'], changed_values)
    if (result.success) {
      notification.success({
        message: 'Update Successful',
        key: 'update'
      })
      set_questionnaire_data({ original: { ...result.data }, ui: { ...await process_questionnaire_for_ui(result.data, [question_key]) } })
    } else {
      notification.error({
        message: 'Failed to update questionnaire',
        description: result.error.toString(),
        key: 'update'
      })
    }

  }

  const process_questionnaire_for_ui = async (questionnaire, expand_keys = []) => {
    let _questionnaire = _.cloneDeep(questionnaire)
    let logged_in_user = retrieveUserInfo()
    //Process Questions Here
    let admin_comments = _.get(_questionnaire, _.get(questionnaire_version_map, _questionnaire['version_number'])['ADMIN_COMMENTS_PATH'])
    let responder_comments = _.get(_questionnaire, _.get(questionnaire_version_map, _questionnaire['version_number'])['RESPONDER_COMMENTS_PATH'])
    let statuses = _.get(_questionnaire, _.get(questionnaire_version_map, _questionnaire['version_number'])['STATUSES_PATH'])
    Object.keys(_.get(_questionnaire, 'renderValue')).forEach((key, idx) => {
      _questionnaire.renderValue[key] = {
        renderType: 'custom',
        panel_color: _.get(STATUS_TYPES_META, _.get(statuses, key, STATUS_TYPES.UNANSWERED)).color,
        expanded: expand_keys.includes(key),
        renderValue: <V2SingleQuestion
          admin_comment={_.get(admin_comments, key, { timestamp: '', value: '' })}
          responder_comment={_.get(responder_comments, key, { timestamp: '', value: '' })}
          questionnaire_key={key}
          status={_.get(statuses, key, STATUS_TYPES.UNANSWERED)}
          on_submit={(changed_values) => on_question_submit(key, changed_values)}
          questionnaire_admin={logged_in_user['cognito:groups'].includes('QUESTIONNAIRE_ADMIN')}
        />,
        name: _.get(_questionnaire, 'renderValue')[key]['name'],
      }
      _questionnaire.statuses[idx] = STATUS_TYPES.UNANSWERED
    })
    return _questionnaire
  }

  return (
    <>
      <Divider></Divider>
      <Row align="middle" justify={'center'}>
        <Col span={22}>
          <GlobalRender
            key={JSON.stringify(questionnaire_data['original'])}
            data={questionnaire_data['ui']}></GlobalRender>

        </Col>
      </Row>
    </>)
}

export default V2Questionnaire