import { blue, green, yellow } from '@ant-design/colors';

export const STATUS_TYPES = {
    'UNANSWERED': 'UNANSWERED',
    'SUBMITTED': 'SUBMITTED',
    'VALID': 'VALID'
}

export const STATUS_TYPES_META = {
    'UNANSWERED': {
        color: undefined
    },
    'SUBMITTED': {
        color:  green[1]
    },
    'VALID': {
        color: blue[0]
    }
}