import { FC, useMemo } from "react";
import { ObjectDataType } from ".";
import MaterialReactTable from "material-react-table";
import { Button, Space, ConfigProvider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Icon } from "@ant-design/compatible";
import { ExportToCsv } from "export-to-csv";
import { utils, writeFile } from 'xlsx'


interface ReactMaterialTableViewProps {
  columns: ObjectDataType;
  data: ObjectDataType;
  isLoading?: boolean;
  initialState?: { [key: string]: any };
  enableEditing?: boolean
}

const ReactMaterialTableView: FC<ReactMaterialTableViewProps> = ({
  columns = [],
  data = [],
  isLoading = false,
  initialState = {},
  enableEditing = false
}) => {
  const viewDataColumns = useMemo(() => columns, [columns]);
  const viewData = useMemo(() => data, [data]);

  const csvOptions: any = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const exportDataToCsv = () => {
    const csvExporter = new ExportToCsv({ ...csvOptions, filename: `${new Date().toISOString()}_data` });
    csvExporter.generateCsv(data);
  };

  const exportDataToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${new Date().toISOString()}_datasheet.xlsx`);
  }

  return (
    <ConfigProvider theme={{}}>
      <MaterialReactTable
      tableInstanceRef={initialState?.['tableInstanceRef'] ? initialState?.['tableInstanceRef'] : null}
        //@ts-ignore
        columns={viewDataColumns}
        renderTopToolbarCustomActions={initialState?.['renderTopToolbarCustomActions'] ? initialState?.['renderTopToolbarCustomActions'] : ({ table }) => {
          return (
            <Space>
              <Button
                type="default"
                icon={
                  <Icon
                    component={() => <img src="icons/excel.svg"></img>}
                    style={{ fontSize: "10px" }}
                  />
                }
                size={"small"}
                onClick={exportDataToExcel}
              >
                Download Excel
              </Button>
              <Button type="default" icon={<DownloadOutlined />} size={"small"} onClick={exportDataToCsv}>
                Download CSV
              </Button>
            </Space>
          );
        }}
        state={{
          isLoading,
          pagination: initialState?.['pagination'] ? initialState['pagination'] : {},
          rowSelection: initialState?.['rowSelection'] ? initialState['rowSelection'] : {}
        }}
      
        data={viewData}
        enableColumnResizing
        onPaginationChange={initialState?.['onPaginationChange'] ? initialState?.['onPaginationChange'] : null}
        enablePagination={initialState?.['enablePagination'] ? initialState?.['enablePagination'] : false}
        muiTablePaginationProps={initialState?.['muiTablePaginationProps'] ? initialState?.['muiTablePaginationProps'] : {}}

        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableFilters={true}
        enableEditing={enableEditing}
        onEditingRowSave={() => console.log('row saved !')}
        editingMode={'row'}
        enableGrouping
        initialState={{ density: "compact", ...initialState }}
        muiTableProps={{
          sx: {
            tableLayout: 'auto',
          },
        }}
        enableStickyHeader
        enableRowSelection={initialState['enableRowSelection'] ? true : false}
        onRowSelectionChange={initialState?.['onRowSelectionChange']}
        renderRowActions={initialState?.['renderRowActions']}
        enableRowActions={initialState?.['enableRowActions']}
        renderBottomToolbar={initialState?.['renderBottomToolbar']}
        renderRowActionMenuItems={initialState?.['renderRowActionMenuItems']}
        defaultColumn={initialState?.['defaultColumn'] ? initialState?.['defaultColumn'] : {}}
        manualPagination={initialState?.['manualPagination'] ? initialState?.['manualPagination'] : false}
        rowCount={initialState?.['rowCount'] ? initialState?.['rowCount'] : undefined}

      />
    </ConfigProvider>
  );
};

export default ReactMaterialTableView;
